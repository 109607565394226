var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;


var flexsliderLoad = require('flexslider'),
	flexslider = require('../modules/slider');


page_home = {
	load: function(){
		this.flexslider();
		// this.mainTxtAnim();
		this.serviziAnim();
		this.servTextAnim();
		this.lavAnim();
	},
	flexslider: function(){
		slider = $('.homeServGal__gall');
		slider.each(function() {
			flexslider.visualFull(slider);
		});

		slider2 = $('.homeVisual__gall');
		slider2.each(function() {
			flexslider.visualFull(slider2);
		});
	},
	// mainTxtAnim: function(){
	// 	if ($(window).outerWidth(true) > 767) {
	// 		var controller = new smInit.Controller(),
	// 			tl = new tmInit(),
	// 			txt = $('.homeVisual__mainText');

	// 			var tween = tl.to(txt, .8, {scale: 1.05, top: '40', ease: Linear.easeInOut});

	// 		var scene = new smInit.Scene({triggerElement: $('#homeVisual_trigger')[0], duration: $(window).outerHeight(true)}).setTween(tween).addTo(controller);
	// 	}
	// },
	servTextAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			txt = $('.homeServGal__textWrap');

			var tween = tl.to(txt, .8, {top: '0', ease: Linear.easeInOut});

		var scene = new smInit.Scene({triggerElement: $('#homeServGal_trigger')[0], duration: $(window).outerHeight(true)}).setTween(tween).addTo(controller);
	},
	serviziAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			tween = tl.staggerTo('.homeServ__el', .25, {y:0, x:0, opacity:1}, .15);
			scene = new smInit.Scene({triggerElement: $('#homeServ_trigger')[0]}).setTween(tween).addTo(controller);
	},
	lavAnim: function(){
		var controller = new smInit.Controller(),
			tl = new tmInit(),
			tween = tl.staggerTo('.homeLav__el', .25, {y:0, x:0, opacity:1}, .15);
			scene = new smInit.Scene({triggerElement: $('#homeLav_trigger')[0]}).setTween(tween).addTo(controller);
	},
}

$(window).on('load', function(){
	page_home.load();
})