module.exports = {
	init: function(){
	},
	visualFull: function (item) {
		if($(item).length===0 ) return false;
		$(item).flexslider({
			animation: 'slide',
			direction: 'horizontal',
			directionNav:   false,
			controlNav:     true,
			animationLoop:  true,
			pauseOnAction:  true,
			pauseOnHover:   false,
			animationSpeed:  800,
			slideshowSpeed:  5000,
			after: function(slider){
				siteGlobal.lazyLoad();
			}
		});
	}
}